
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import ImageView from '@/components/ImageView.vue'
import OwnerSet from '@/components/OwnerSet.vue'
import PageUtil from "@/utils/page_util";
import {IUserItem, IUserUpdateItem} from "@/models/user_models";

interface IState {
  list: IUserItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  editItem?: IUserUpdateItem
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '头像',
    dataIndex: 'avatar',
    key: 'avatar',
  },
  {
    title: '昵称',
    dataIndex: 'nickname',
    key: 'nickname',
  }, {
    title: '性别',
    dataIndex: 'sex',
    key: 'sex',
  },
  {
    title: '生日',
    dataIndex: 'birthday',
    key: 'birthday'
  },
  {
    title: '电话',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    title: '等级',
    dataIndex: 'level_name',
    key: 'level_name',
  },
  {
    title: '最近登录日期',
    dataIndex: 'login_time',
    key: 'login_time',
  },
  {
    title: '登录ip地址',
    dataIndex: 'login_ip',
    key: 'login_ip',
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark'
  },
  {
    title: '状态',
    dataIndex: 'disable',
    key: 'disable',
  },
  {
    title: '操作',
    key: 'action',
  },
];

export default defineComponent({
  name: 'OwnerList',
  components: {ImageView, OwnerSet},
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      editItem: undefined,
      visible: false,
      searches: {
        page: PageUtil.pageNo,
        limit: PageUtil.pageSize,
        keyword_type: '',
        keyword: ''
      },
    })


    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page,
      pageSize: state.searches.limit,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.limit = pages.pageSize
      state.searches.page = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      for (let key in params) {
        if (params[key] === '') {
          delete params[key]
        }
      }
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getOwnerList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.lists || []
      }
    }

    // 设置modal显示隐藏
    const setVisible = (item: IUserItem | undefined, flg: boolean) => {
      console.log(item)
      state.visible = flg
      if (item && flg) {
        state.editItem = {
          id: item.id,
          nickname: item.nickname,
          avatar: item.avatar,
          mobile: item.mobile,
          birthday: item.birthday,
          select: '',
          remark: item.remark
        }
      } else {
        setTimeout(() => state.editItem = undefined, 300)
      }
    }

    const onClose = () => {
      state.visible = false
      setTimeout(() => state.editItem = undefined, 300)
      getList()
    }

    // 设置启用禁用
    const onSetStatus = async (item: IUserItem) => {
      state.loading = true
      const res = await ApiLogistics.setOwnerStatus({
        "id": item.id,
        "status": Number(item.disable === 0),
      })
      state.loading = false
      setVisible(item, false)
      if (res && res.error_code === 0) {
        message.success('操作成功')
        await getList()
      } else {
        message.error(res?.msg || '审核失败');
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches.keyword_type = key
      state.searches.keyword = value
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page = 1
      state.searches.limit = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      onSetStatus,
      setVisible,
      onClose,
    }
  }
})

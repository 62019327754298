
// 设置分类
import {
  defineComponent,
  reactive,
  ref,
  toRaw,
  toRefs,
} from "vue"
import type {UnwrapRef} from "vue"
import ApiLogistics from "@/request/apis/api_logistics"
import {message} from "ant-design-vue/es"
import UploadComponent from "@/components/UploadComponent.vue"
import {IUserUpdateItem} from "@/models/user_models";

interface IState {
  loading: boolean
  editItem?: IUserUpdateItem
}

export default defineComponent({
  props: ["editItem", "reset"],
  name: "OwnerSet",
  components: {UploadComponent},
  setup(props) {
    const state: IState = reactive({
      loading: false,
      list: [],
    })

    const formRef = ref()
    const formState: UnwrapRef<IUserUpdateItem> = reactive({...props.editItem})

    const rules = {
      nickname: [
        {
          required: true,
          message: "请输入昵称",
          trigger: ["change", "blur"],
        },
      ],
      mobile: [
        {
          required: true,
          message: "请输入电话",
          trigger: ["change", "blur"],
        },
      ],
      avatar: [{required: true, message: '请上传头像', trigger: ['change', 'blur']}],
    }

    const onSubmit = () => {
      formRef.value
          .validate()
          .then(() => {
            onEdit()
          })
          .catch((error: any) => {
            console.log("error", error)
          })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }

    // 编辑
    const onEdit = async () => {
      state.loading = true
      const res = await ApiLogistics.setOwner({
        ...toRaw(formState),
      })
      state.loading = false
      if (res) {
        message.destroy()
        if (res.error_code === 0) {
          message.success("编辑成功")
          if (props.reset) {
            props.reset()
          }
          return
        }
        message.error(res.msg)
      }
    }

    const onImageChange = (url: string) => {
      formState.avatar = url
    }

    return {
      ...toRefs(state),
      formRef,
      labelCol: {span: 6},
      wrapperCol: {span: 18},
      other: "",
      formState,
      rules,
      onSubmit,
      resetForm,
      onEdit,
      onImageChange,
    }
  },
})
